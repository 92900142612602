import React, { useState, useEffect } from "react";
import Header from "./components/Header/Header";
import "./App.css";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Skills from "./components/Skill/Skills";
import Services from "./components/Services/Services";
import Qualification from "./components/Qualification/Qualification";
import Contact from "./components/Contact/Contact";
import Footer from "./components/footer/Footer";
import ScrollUp from "./components/ScroolUp/ScrollUp";
import Work from "./components/work/Work";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/Loader/Loader";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Changed to exactly 2 seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <main className="main">
            <Home />
            <About />
            <Work />
            <Skills />
            <Qualification />
            <Services />
            <Contact />
          </main>
          <Footer />
          <ScrollUp />
          <ToastContainer />
        </>
      )}
    </>
  );
};

export default App;
