import React from "react";
import { useState } from "react";
import "./Services.css";
import { useDarkMode } from "../../context/DarkModeContext";
import { motion } from "framer-motion";

const Services = () => {
  const { darkMode } = useDarkMode();
  const [toggleState, setToggleState] = useState(0);
   const toggleTab = (index) => {
     setToggleState(index);

     // Toggle body and html classes to disable/enable scrolling
     if (index !== 0) {
       document.body.classList.add("modal-open");
       document.documentElement.classList.add("modal-open");
     } else {
       document.body.classList.remove("modal-open");
       document.documentElement.classList.remove("modal-open");
     }
   };
  return (
    <section
      className={`services section ${darkMode ? "dark-theme" : ""}`}
      id="services"
    >
      <h2 className="section__title">Services</h2>
      <span className="section__subtitle">
        <span style={{ color: "#F7AB04" }}>What</span> i offer
      </span>
      <div className="services__container container grid">
        <motion.div
          initial={{
            y: -200,
            opacity: 0,
          }}
          transition={{
            duration: 1.2,
          }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="services__content"
        >
          <div>
            <i
              className="uil uil-arrow services__icon"
              style={{ color: "#F7AB04" }}
            ></i>
            <h3 className="services__title">
              Full Stack <br /> Development
            </h3>
          </div>

          <span className="services__button" onClick={() => toggleTab(1)}>
            <span style={{ color: "#F7AB04" }}>Click</span> here
            <i className="uil uil-arrow-right services__button-icon"></i>
          </span>
          <div
            className={
              toggleState === 1
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                onClick={() => toggleTab(0)}
                className="uil uil-times services__modal-close"
              ></i>
              <h3 className="services__modal-title">
                Full Stack <br /> Development
              </h3>
              <p className="services__modal-description">
                Service with more than 1 years of experience.Providing quality
                work to clients and companies.
              </p>
              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Frontend Development: Building modern, responsive web
                    applications using React and Next.js.
                  </p>
                </li>
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Backend Development: Developing robust backends with
                    databases like MongoDB, MySQL, and Firebase.
                  </p>
                </li>
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Legacy Code Handling: Expert in maintaining, upgrading, and
                    improving legacy systems while ensuring smooth integration
                    of new features.
                  </p>
                </li>
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Figma to Code: Proficient in converting Figma designs into
                    pixel-perfect web applications.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </motion.div>
        <div className="services__content">
          <div>
            <i
              className="uil uil-image-resize-landscape services__icon"
              style={{ color: "#F7AB04" }}
            ></i>
            <h3 className="services__title">
              Graphic <br /> Design (Basic)
            </h3>
          </div>

          <span className="services__button" onClick={() => toggleTab(2)}>
            <span style={{ color: "#F7AB04" }}>Click</span> here
            <i className="uil uil-arrow-right services__button-icon"></i>
          </span>
          <div
            className={
              toggleState === 2
                ? "services__modal active-modal"
                : "services__modal"
            }
          >
            <div className="services__modal-content">
              <i
                onClick={() => toggleTab(0)}
                className="uil uil-times services__modal-close"
              ></i>
              <h3 className="services__modal-title">
                Graphic <br /> Design (Basic)
              </h3>
              <p className="services__modal-description">
                While not a professional graphic designer, I have experience
                creating vector graphics, logos, banners, and posters for
                various projects.
              </p>
              <ul className="services__modal-services grid">
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Creating simple vector graphics and logos.
                  </p>
                </li>
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Designing basic banners and posters.
                  </p>
                </li>
                <li className="services__modal-service">
                  <i className="uil uil-check-circle services__modal-icon"></i>
                  <p className="services__modal-info">
                    Basic knowledge of design tools like Adobe Illustrator and
                    Photoshop.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
