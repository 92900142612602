import React from 'react';
import './Loader.css';

const Loader = () => {
    return (
        <div class="loader-container">
            <div class="circular-loader"></div>
            <div class="text-loader">
                <span class="letter">A</span>
                <span class="letter">S</span>
                <span class="letter">A</span>
                <span class="letter">D</span>
            </div>
        </div>
    );
};

export default Loader;