import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./contact.css";
import { useDarkMode } from "../../context/DarkModeContext";
import Phone from "../../assets/phone.png";
import whatsapp from "../../assets/whatsapp.png";
import gmail from "../../assets/gmail.png";
import { toast } from "react-toastify";

const Contact = () => {
  const { darkMode } = useDarkMode();
  const [isSending, setIsSending] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true);

    // Combine first name and last name into a single field
    const fullName = `${formData.firstName} ${formData.lastName}`;

    emailjs
      .send(
        "service_ghmvprk",
        "template_4dakteq",
        {
          from_name: fullName, // Use fullName here
          name: fullName, // Use fullName here
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
        },
        "OGCRV8ZuFhGscbnIP" // Your User ID
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Message Sent Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
          });
          setIsSending(false);
        },
        (error) => {
          console.log(error.text);
          toast.error("Failed to send message, please try again.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsSending(false);
        }
      );

  };



  return (
    <section
      className={`contact section ${darkMode ? "dark-theme" : ""}`}
      id="contact"
    >
      <h2 className="section__title">Get in touch</h2>
      <span className="section__subtitle">
        <span style={{ color: "#F7AB04" }}>Contact</span> Me
      </span>
      <div className="contact__container">
        {/* Contact Info Cards */}
        <div className="contact__info">
          <div className="contact__card">
            <img src={gmail} width={40} alt="" />
            <h3 className="contact__card-title">Email</h3>
            <span className="contact__card-data">asadshah1024@gmail.com</span>
            <a
              href="mailto:asadshah1024@gmail.com.com"
              className="contact__button"
            >
              <span style={{ color: "#F7AB04" }}>Write</span> me{""}
              <i className="bx bx-right-arrow-alt contact__button-icon"></i>
            </a>
          </div>
          <div className="contact__card">
            <img src={whatsapp} width={40} alt="" />
            <h3 className="contact__card-title">Whatsapp</h3>
            <span className="contact__card-data">+923040630451</span>
            <a
              href="https://wa.me/+923040630451"
              target="_blank"
              className="contact__button"
              rel="noreferrer"
            >
              <span style={{ color: "#F7AB04" }}>Write</span> me{""}
              <i className="bx bx-right-arrow-alt contact__button-icon"></i>
            </a>
          </div>
          <div className="contact__card">
            <img src={Phone} width={40} alt="" />
            <h3 className="contact__card-title">Phone</h3>
            <span className="contact__card-data">+923040630451</span>
            <a
              href="tel:+923040630451"
              rel="noreferrer"
              className="contact__button"
              target="_blank"
            >
              <span style={{ color: "#F7AB04" }}>Call</span> me{""}
              <i className="bx bx-right-arrow-alt contact__button-icon"></i>
            </a>
          </div>
        </div>

        {/* Contact Form */}
        <div className="contact__form-container">
          <form className="contact__form" onSubmit={sendEmail}>
            <div className="contact__form-grid">
              <div className="contact__form-div">
                <label className="contact__form-tag">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="contact__form-input"
                  required
                  autocomplete="off"
                />
                <svg
                  className="contact__form-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12 2a5 5 0 1 0 5 5a5 5 0 0 0-5-5m0 8a3 3 0 1 1 3-3a3 3 0 0 1-3 3m9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"
                  />
                </svg>
              </div>
              <div className="contact__form-div">
                <label className="contact__form-tag">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="contact__form-input"
                  required
                  autocomplete="off"
                />
                <svg
                  className="contact__form-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12 2a5 5 0 1 0 5 5a5 5 0 0 0-5-5m0 8a3 3 0 1 1 3-3a3 3 0 0 1-3 3m9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"
                  />
                </svg>
              </div>
              <div className="contact__form-div">
                <label className="contact__form-tag">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="contact__form-input"
                  required
                  autocomplete="off"
                />
                <svg
                  className="contact__form-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M20 4H6c-1.103 0-2 .897-2 2v5h2V8l6.4 4.8a1 1 0 0 0 1.2 0L20 8v9h-8v2h8c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2m-7 6.75L6.666 6h12.668z"
                  />
                  <path
                    fill="currentColor"
                    d="M2 12h7v2H2zm2 3h6v2H4zm3 3h4v2H7z"
                  />
                </svg>
              </div>
              <div className="contact__form-div">
                <label className="contact__form-tag">Phone</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="contact__form-input"
                  required
                  autocomplete="off"
                />
                <svg
                  className="contact__form-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M17.707 12.293a1 1 0 0 0-1.414 0l-1.594 1.594c-.739-.22-2.118-.72-2.992-1.594s-1.374-2.253-1.594-2.992l1.594-1.594a1 1 0 0 0 0-1.414l-4-4a1 1 0 0 0-1.414 0L3.581 5.005c-.38.38-.594.902-.586 1.435c.023 1.424.4 6.37 4.298 10.268s8.844 4.274 10.269 4.298h.028c.528 0 1.027-.208 1.405-.586l2.712-2.712a1 1 0 0 0 0-1.414zm-.127 6.712c-1.248-.021-5.518-.356-8.873-3.712c-3.366-3.366-3.692-7.651-3.712-8.874L7 4.414L9.586 7L8.293 8.293a1 1 0 0 0-.272.912c.024.115.611 2.842 2.271 4.502s4.387 2.247 4.502 2.271a.99.99 0 0 0 .912-.271L17 14.414L19.586 17z"
                  />
                </svg>
              </div>
            </div>
            <div className="contact__form-div contact__form-area">
              <label className="contact__form-tag">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="contact__form-input"
                required
              ></textarea>
            </div>
            <button className="button button--flex">
              <span style={{ color: "#F7AB04" }}>
                {isSending ? "Sending..." : "Send"}
              </span>
              {!isSending && (
                <span style={{ color: "#fff" }}>&nbsp; Message</span>
              )}
              <svg
                className="button__icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill=""
              >
                <path
                  d="M14.2199 21.9352C13.0399 21.9352 11.3699 21.1052 10.0499 17.1352L9.32988 14.9752L7.16988 14.2552C3.20988 12.9352 2.37988 11.2652 2.37988 10.0852C2.37988 8.91525 3.20988 7.23525 7.16988 5.90525L15.6599 3.07525C17.7799 2.36525 19.5499 2.57525 20.6399 3.65525C21.7299 4.73525 21.9399 6.51525 21.2299 8.63525L18.3999 17.1252C17.0699 21.1052 15.3999 21.9352 14.2199 21.9352ZM7.63988 7.33525C4.85988 8.26525 3.86988 9.36525 3.86988 10.0852C3.86988 10.8052 4.85988 11.9052 7.63988 12.8252L10.1599 13.6652C10.3799 13.7352 10.5599 13.9152 10.6299 14.1352L11.4699 16.6552C12.3899 19.4352 13.4999 20.4252 14.2199 20.4252C14.9399 20.4252 16.0399 19.4352 16.9699 16.6552L19.7999 8.16525C20.3099 6.62525 20.2199 5.36525 19.5699 4.71525C18.9199 4.06525 17.6599 3.98525 16.1299 4.49525L7.63988 7.33525Z"
                  fill={darkMode ? "#fff" : "var(--container-color)"}
                ></path>
                <path
                  d="M10.11 14.7052C9.92005 14.7052 9.73005 14.6352 9.58005 14.4852C9.29005 14.1952 9.29005 13.7152 9.58005 13.4252L13.16 9.83518C13.45 9.54518 13.93 9.54518 14.22 9.83518C14.51 10.1252 14.51 10.6052 14.22 10.8952L10.64 14.4852C10.5 14.6352 10.3 14.7052 10.11 14.7052Z"
                  fill={darkMode ? "#fff" : "var(--container-color)"}
                ></path>
              </svg>
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
