import React from "react";
import "./Qualification.css";
import { useState } from "react";
import { useDarkMode } from "../../context/DarkModeContext";

const Qualification = () => {
  const { darkMode } = useDarkMode();
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section
      className={`qualification section ${darkMode ? "dark-theme" : ""}`}
      id="qualification"
    >
      <h2 className="section__title">Portfolio</h2>
      <span className="section__subtitle">
        My{" "}
        <span
          style={{
            color: "#F7AB04",
          }}
        >
          Personal
        </span>{" "}
        journey
      </span>
      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
              toggleState === 1
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            style={{
              backgroundColor: toggleState === 1 ? "#F7AB04" : "",
              color: toggleState === 1 ? "white" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 20px",
              borderRadius: "8px", // Optional for rounded corners
            }}
            onClick={() => toggleTab(1)}
          >
            <i
              className="uil uil-briefcase-alt qualification__icon"
              style={{
                color: toggleState === 1 ? "white" : "",
                marginRight: "8px",
              }}
            ></i>
            Experience
          </div>
          <div
            className={
              toggleState === 2
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            style={{
              backgroundColor: toggleState === 2 ? "#F7AB04" : "",
              color: toggleState === 2 ? "white" : "",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 20px",
              borderRadius: "8px", // Optional for rounded corners
            }}
            onClick={() => toggleTab(2)}
          >
            <i
              className="uil uil-graduation-cap qualification__icon"
              style={{
                color: toggleState === 2 ? "white" : "",
                marginRight: "8px",
              }}
            ></i>
            Education
          </div>
        </div>

        <div className="qualification__sections">
          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Full Stack Developer</h3>
                <span className="qualification__subtitle">
                  NoveltySoft Software Solutions
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt">2023 - Present</i>
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">Web & Graphics Tutor</h3>
                <span className="qualification__subtitle">
                  Private Institute
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt">2022 - 2023</i>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              toggleState === 2
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">BS Computer Science</h3>
                <span className="qualification__subtitle">
                  Islamia University Bahawalpur
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt">2019-2023</i>
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">
                  Intermediate of Computer Science
                </h3>
                <span className="qualification__subtitle">
                  Punjab College Hasilpur
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt">2017-2019</i>
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">Matriculation</h3>
                <span className="qualification__subtitle">
                  Technical High School Bahawalpur
                </span>
                <div className="qualification__calender">
                  <i className="uil uil-calendar-alt">2015-2017</i>
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              {/* <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div> */}
              <div>
                <h3 className="qualification__title"></h3>
                <span className="qualification__subtitle"></span>
                <div className="qualification__calender">
                  <i className="uil uil-calender-alt"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;
