import Work1 from "../../assets/w1.png";
import Work2 from "../../assets/w2.png";
import Work3 from "../../assets/w3.png";
import Work4 from "../../assets/w4.png";
import Work5 from "../../assets/w5.PNG";
import Work6 from "../../assets/w6.PNG";
import react from "../../assets/Skills/react.png";
import next from "../../assets/Skills/next.png";
import mongodb from "../../assets/Skills/mongodb.png";
import javascript from "../../assets/Skills/javascript.png";
import firebase from "../../assets/Skills/firebase.png";
import redux from "../../assets/Skills/redux.png";
import node from "../../assets/Skills/node2.png";
import mysql from "../../assets/Skills/mysql2.png";
import sass from "../../assets/Skills/sass.png";
import framer from "../../assets/Skills/framermotion.png";
import mui from "../../assets/Skills/material.png";
import tailwind from "../../assets/Skills/tailwind.png";
import bootstrap from "../../assets/Skills/bootstrap.png";
import figma from "../../assets/Skills/figma.png";
import rapid from "../../assets/Skills/rapid.png";
import express from "../../assets/Skills/express.png"
import stripe from "../../assets/Skills/stripe.png"

export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: "House Marketplace ",
    category: "React",
    url: "https://housemarketplace-rosy.vercel.app/",
    repo: "https://github.com/Asad1024/Marketplace",
    description:
      "Simplified house hunting and listing with search, view, contact, and post feature",
    techStack: [react, firebase, tailwind, framer],
  },
  {
    id: 2,
    image: Work2,
    title: "TubeVibe (YT Clone)",
    category: "React",
    url: "https://tubevibe.netlify.app/",
    repo: "https://github.com/Asad1024/Youtube-Clone",
    description: "A YouTube clone application with video search functionality.",
    techStack: [react, rapid, mui],
  },
  {
    id: 3,
    image: Work3,
    title: "MysticShop (E-com)",
    category: "MERN",
    url: "https://mysticshop.netlify.app/",
    repo: "https://github.com/Asad1024/Ecommerce-App-MysticShop",
    description:
      "An e-commerce app with a secure payment system, and an admin dashboard.",
    techStack: [react, mongodb, express, node, stripe],
  },
  {
    id: 4,
    image: Work4,
    title: "Wheelz Car",
    category: "Next",
    url: "https://wheelz-case.vercel.app/",
    repo: "https://github.com/Asad1024/Car-Hub-Wheelz",
    description:
      "Enabled users to search for cars by model, make, and other criteria, with built-in filters for a seamless browsing experience.",
    techStack: [next,rapid, tailwind],
  },
  {
    id: 5,
    image: Work5,
    title: "Food Delivery App",
    url: "https://app.gemoo.com/share/image-annotation/574269030284660736?codeId=M0GAy9XO23Ojq&origin=imageurlgenerator",
    category: "Figma",
  },
  {
    id: 6,
    image: Work6,
    title: "Joker",
    category: "Illustration",
  },
];

export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "React",
  },
  {
    name: "Next",
  },
  {
    name: "MERN",
  },
  {
    name: "Figma"
  },
  {
    name: "Illustration",
  },
];
